export function getBrandFromQuery () {
  const params = new URLSearchParams(window.location.search)
  const brand = params.get('brand')
  if (brand) {
    history.replaceState(null, '', window.location.pathname)
  }
  return brand
}

export function setBrandToStorage (brand) {
  localStorage.setItem('brand', brand)
}

export function getBrandFromStorage () {
  return localStorage.getItem('brand') || 'solem'
}
