<template>
  <div id="app">
    <HeaderComponent />
    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/Header.vue'
import FooterComponent from './components/Footer.vue'
import { getBrandFromQuery, setBrandToStorage, getBrandFromStorage } from '@/brandConfig'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
  created () {
    const brand = getBrandFromQuery() || getBrandFromStorage()
    setBrandToStorage(brand)
    document.documentElement.setAttribute('data-brand', brand)
    console.log(brand)
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';
</style>
