<template>
  <header class="bg">
    <nav class="navbar nav two navbar-expand-lg navbar-light"
      :class="$route.path.includes('/denuncia') || $route.path.includes('/consulta') ? 'mb-3' : 'm-0'">
      <div class="container flex-column flex-sm-row justify-content-center justify-content-sm-between">
        <router-link to="/home">
          <img class="navbar-brand m-0" src="@/assets/img/solem.svg" alt="SOLEM">
        </router-link>
        <h3 v-if="$route.path.includes('/denuncia') || $route.path.includes('/resultado')" class="title purple">
          Formulario de denuncia</h3>
        <h3 v-else-if="$route.path.includes('/consulta') || $route.path.includes('/resultado')" class="title blue">
          Formulario de consulta</h3>
        <div v-else class="justify-content-start justify-content-sm-end d-flex">
          <button type="button" v-if="!authState.isAuthenticated" class="btn btn-secondary-login text-semibold mt-2 mt-sm-0" @click="login">Iniciar Sesión</button>
        </div>
        <div class="dropdown mt-2 mt-sm-0" v-if="authState.isAuthenticated">
          <button class="btn btn-secondary dropdown-toggle" :title="this.usermail" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            <span class="name">{{ this.viewusername }}</span>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click="logout">Cerrar Sesión</a></li>
            <li><a class="dropdown-item disabled" href="#">Versión {{ version }}</a></li>
          </ul>
        </div>
      </div>
    </nav>
    <nav aria-label="breadcrumb" v-if="$route.path.includes('/denuncia') || $route.path.includes('/consulta')">
      <div class="container">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item"><a href="/home">Canal de denuncias y consultas</a></li>
          <li class="breadcrumb-item" v-if="$route.path.includes('/compliance')">Compliance</li>
          <li class="breadcrumb-item" v-if="$route.path.includes('/leykarin')">Ley Karin</li>
          <li class="breadcrumb-item active" aria-current="page">
            <span v-if="$route.path.includes('/denuncia')">Realizar denuncia</span>
            <span v-if="$route.path.includes('/consulta')">Realizar consulta</span>
          </li>
        </ol>
      </div>
    </nav>
  </header>
</template>

<script>
import { authState } from '@/authState'
import { KeycloakInstance } from '@/keycloak'

export default {
  name: 'HeaderComponent',
  data: function () {
    return {
      viewusername: '',
      usermail: '',
      version: process.env.VUE_APP_VERSION
    }
  },
  setup () {
    return { authState }
  },
  methods: {
    login () {
      KeycloakInstance.login({ redirectUri: window.location.origin })
    },
    logout () {
      KeycloakInstance.logout({ redirectUri: window.location.origin })
    }
  },
  computed: {
    currentRoute () {
      return this.$route.path
    }
  },
  created () {
    if (KeycloakInstance.authenticated && KeycloakInstance.tokenParsed && KeycloakInstance.tokenParsed.name) {
      this.viewusername = KeycloakInstance.tokenParsed.name
      this.usermail = KeycloakInstance.tokenParsed.email
    }
  }
}
</script>
